<template>
  <b-list-group class="mt-1">
    <b-list-group-item active class="py-0">
      {{label}}
    </b-list-group-item>
    <b-list-group-item 
      v-for="(feature,i) in features" 
      :to="feature.get('id_survey')?`/forms/${form_id}/edit/${feature.get('id_survey')}`:''" 
      :key="'identity-'+layer_id+i" 
      class="py-1"
      @mouseover="flashFeature(feature)" 
      @mouseleave="flashFeature(null)"
    >
      <span>{{getFeatureText(feature)}}</span>
      <i v-if="allowCopyFeatureText" class="mdi mdi-content-copy ml-1" @click.stop.prevent="copyClipboard(getFeatureText(feature))"></i>
    </b-list-group-item>
  </b-list-group>
</template>

<script>
import base from './_base.js'

export default{
  name:'map-identity-layer',
  mixins:[base],
  props:['layer_id','features'],
  data(){
    return{
      featureOverlay:null,
    }
  },
  computed:{
    label(){return this.$store.getters['map/'+this.layer_id+'/label']},
    visible(){return this.$store.getters['map/'+this.layer_id+'/visible']},
    allowCopyFeatureText(){return this.$store.getters['map/'+this.layer_id+'/allowCopyFeatureText']},
    form_id(){return this.$store.getters['map/'+this.layer_id+'/config'].couchdb_form}
  },
  methods:{
    init(){
    },
    getFeatureText(feature){
      if(feature.getProperties()['map_label']){
        return feature.getProperties()['map_label']
      }else if(this.$store.getters['map/'+this.layer_id+'/config']?.identity?.fields){
        return this.$store.getters['map/'+this.layer_id+'/config'].identity.fields.map(x=>{
          return feature.getProperties()[x]
        }).filter(x=>!!x).join("-")
      }else if(feature.getProperties()['id_survey']){
        return feature.getProperties()['id_survey']
      }else{
        return feature.getId() ?? 'feature'
      }
    },
    copyClipboard(text){
      navigator.clipboard.writeText(text)
    },
    flashFeature(feature){
      if(!this.featureOverlay && this.map){
        // https://openlayers.org/en/latest/examples/hitdetect-vector.html
        this.featureOverlay = new ol.layer.Vector({
          source: new ol.source.Vector(),
          map: this.map,
          style: function (feature) {
            // highlightStyle.getText().setText(feature.get('name'));
            return highlightStyle;
          },
        });
      }
      if(this.featureOverlay){
        this.featureOverlay.getSource().clear()
        if(feature){
          this.featureOverlay.getSource().addFeature(feature)
        }
      }
    },
  },
}

const highlightStyle = new ol.style.Style({
  stroke: new ol.style.Stroke({
    color: '#f00',
    width: 5,
  }),
  fill: new ol.style.Fill({
    color: 'rgba(255,0,0,0.2)',
  }),
  image: new ol.style.RegularShape({
    // fill: fill,
    stroke: new ol.style.Stroke({color: 'red', width: 2}),
    points: 4,
    radius: 20,
    radius2: 0,
    angle: Math.PI / 4,
  })
});
</script>
