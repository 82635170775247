<template>
  <div v-if="showElement" class="py-2" >
    <input-row 
      :showElement="showElement"
      :label="label" 
      :elId="elId" 
      :status="repeatStatus" 
      :config="config"
      :me="me"
    >
      <template v-slot:sublabel>
        <div class="ml-3 mb-1" v-if="!readonly">
          <b-button @click="addNew" variant="primary" size="sm" v-if="!readonly && !parameters.disable_add_new">
            <!-- {{labels.inputs.add}} -->
            <span class="glyphicon glyphicon-plus"></span>
          </b-button>
          <b-button @click="deleteCurrent" 
              class="ml-3" 
              size="sm" variant="danger" 
              :title="labels.inputs.delete"
              v-if="editingIndex!=-1 && editingElement && !parameters.disable_delete"
              >
            <!-- {{labels.inputs.delete}} -->
            <span class="glyphicon glyphicon-remove"></span>
          </b-button>
        </div>
      </template>

      <div v-show="value.length>0">
        <b-table :items="valueForTable" :fields="fields" responsive small class="noPadBottom" >
          <template slot="cell(editButton)" slot-scope="data">
            <b-button @click="clickIndex(data.index)" size="sm" :variant="getVariantStatus(data.index)" 
              class=" p-1">
              <!-- {{labels.inputs.edit}} -->
              <span class="glyphicon glyphicon-pencil"></span>
            </b-button>
          </template>
        </b-table>
      </div>
      <div v-if="editingIndex!=-1 && editingElement" :id="newElementId">
        <!-- Input group -->
        <i-group
            :config="config"
            :formReadOnly="formReadOnly"
            :formDefinition="formDefinition"
            :value="editingElement"
            @input="updateValue($event)"
            :status="editingStatus"
            :showSystemValues="true"
            :surveyDoc="surveyDoc"
            :namePath="newNamePath(editingIndex)"
            :showHeader="false"
            :isRootRepeat="true"
            :editingIndexGiven="editingIndexGiven"
            :readOnlyInput="readOnlyInput"
            >
        </i-group>

      </div>
    </input-row>
  </div>
</template>

<script>
import repeatBase from './_baseRepeat.js'
import formUtils from '../../utils/form_utils.js'

export default {
  name:'i-repeat-compact',
  mixins:[repeatBase],
  computed:{
    repeatStatus(){
      if(Array.isArray(this.status)){
        return formUtils.getStatusOfTree(this.status)
      }
      return this.status
    },
  },
  watch:{
  },
  methods:{
  }
}
</script>

<style scoped>
.my-card-body{
  padding: 0.5rem; 
}
.noPadBottom {
  margin-bottom: -0.80rem !important;
}

</style>
