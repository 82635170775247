// Implements layer.js...
// import stylefunction from 'ol-mapbox-style/stylefunction';
var stylefunction=olms.applyStyle
// import stylefunction from 'ol-mapbox-style/stylefunction';
// import {applyStyle} from 'ol-mapbox-style';
// let stylefunction=stylefunction0.stylefunction
//var stylefunction= require('ol-mapbox-style/stylefunction')
import axios from 'axios'
import utils_ol from './utils_ol.js'

export default {
  namespaced: true,
  state: {
    className:'layer_vector',
    define_from_config_sub_tasks: [],
  },
  getters: {
    identityActivated(state){
      return ( state.config.identity && state.config.identity.pixels >= 0 )? true : false
    }
  },
  mutations: {

  },
  actions: {

    define_from_config_sub(context,config){
      return context.dispatch('define_source',config).then(source1=>{
        let opts={
          source: source1,
          visible:config.visible,
        }
        if(config.hasOwnProperty('renderMode')){
          //https://openlayers.org/en/latest/apidoc/ol.layer.Vector.html
          // image work better with many points... clustering not tested tested
          opts.renderMode=config.renderMode
        }
        let olLayer=new ol.layer.Vector(opts)
        //this.map.addLayer(olLayer)
        context.state.map_layer=olLayer
        context.dispatch('setBaseStyle')
        let tasks=context.state.define_from_config_sub_tasks.map(x=>{
          return context.dispatch(x,config)
        }) // in case we need some async data
        return Promise.all(tasks)
      }).then(rep=>{
        return Promise.resolve(config.layer_id)
      })
    },
    setBaseStyle(context){
      const config = context.state.config
      const olLayer = context.state.map_layer
      if(olLayer){
        if(config.ol_style_feature_rule){
          olLayer.setStyle(utils_ol.makStyleFeatureRule(config.ol_style_feature_rule))
        }else if(config.ol_style){
          olLayer.setStyle(utils_ol.makeStyle(config.ol_style))
        }else if(config.mapbox_style){
          stylefunction(olLayer, config.mapbox_style, config.mapbox_style.name);
        }
      }
    },
    define_source(context,config){ // we make a function of it as we want it to be overrided by other implementation
      // for url see: getLayers in project.js - for couchdb it's the first attachment.
      let source1=null
      if (config.type=='kml') {
        source1=new ol.source.Vector({
          // see getLayers in src\store\project.js
          url: config.url,
          format: new ol.format.KML()
        })
      }else if (config.type=='geojson') {
        source1=new ol.source.Vector({
          format: new ol.format.GeoJSON(
            {
              // TODO: Make it work... verify that work, cannot figure this one out. For now provide vector in 4326 works
              // defaultDataProjection: config.projection ,
              // dataProjection: config.projection || 'EPSG:3857',
              // featureProjection: 'EPSG:3857'
              // featureProjection: config.projection || 'EPSG:3857'
            }
          ),
          // see getLayers in src\store\project.js
          url: config.url
        })
        console.log(source1.getProjection()); 
      }else if (config.type=='geojson_raw') {
        // tested to knwo if better on memory usage, but don't look that way.
        source1=new ol.source.Vector({
          features: new ol.format.GeoJSON().readFeatures( config.geojson, {featureProjection: config.projection || 'EPSG:3857'})
        })
        // console.log('***');
        // console.log(source1.getFeatures().length);
        // config.geojson = null // no more needed
      }else{
        console.log(config);
        console.error('Not a layer type defined, see previopus logged config')
        return Promise.resolve(null)
      }
      return Promise.resolve(source1)
    },
    getFeatures(context,coordinate){
      if(context.state.map_layer && context.state.config.getfeatures_hide != true){
        // let features=context.state.map_layer.getSource().getFeaturesAtCoordinate(coordinate)
        // return Promise.resolve(features)
        //TODO: when point layer, put an extent in function of the map scale.
        // if some day we want a buffer: getFeaturesInExtent or forEachFeatureIntersectingExtent
        // opt_options.hitTolerance * context.rootGetters['']
        // https://github.com/openlayers/openlayers/blob/v5.3.0/src/ol/PluggableMap.js#L567
        // opt_options.hitTolerance * this.frameState_.pixelRatio : 0;
        // const buf=context.rootState.map.map.frameState_.pixelRatio*5

        const zoom = context.rootState.map.map.getView().getZoom()
        const latLng=ol.proj.transform(context.rootState.map.map.getView().getCenter(), context.rootState.map.map.getView().getProjection(), ol.proj.get('EPSG:4326'))
        //https://gis.stackexchange.com/a/127949/2043
        // console.log(latLng);
        
        const metersPerPx = 156543.03392 * Math.cos(latLng[1] * Math.PI / 180) / Math.pow(2, zoom)
        let pixels = context.rootState.localConfig.jsonConfig.mapIdentityPixel ?? context.state.config?.identity?.pixels ?? 3
        pixels = pixels * window.devicePixelRatio // for retina or other mobile dense screen
        const tol=Math.abs( metersPerPx*pixels)
        //consttruct an extent
        //[minx, miny, maxx, maxy].
        // console.log('tolerance: ' + tol.toString());
        let features=context.state.map_layer.getSource().getFeaturesInExtent([coordinate[0]-tol,coordinate[1]-tol,coordinate[0]+tol,coordinate[1]+tol])
        return Promise.resolve(features)
      }else{
        return Promise.resolve([])
      }
      
    },
    getFeaturesAtPixel00(context,pixel){
      // if(context.state.map_layer && context.state.config.getfeatures_hide != true){
      if(context.state.map_layer ){
        // https://openlayers.org/en/latest/examples/hitdetect-vector.html
        let features=context.state.map_layer.getFeatures(pixel)
        return Promise.resolve(features)
      }else{
        return Promise.resolve([])
      }
      
    },
    getFeaturesAtPixel(context,options){
      // if(context.state.map_layer && context.state.config.getfeatures_hide != true){
      if(context.state.map_layer ){
        // https://openlayers.org/en/latest/examples/hitdetect-vector.html
        // https://openlayers.org/en/v4.6.5/apidoc/
        // TODO: getFeatures is not implemented in our version of openlayres...
        // let features=context.state.map_layer.getFeatures(pixel)
        let hitTolerance = context.rootState.localConfig.jsonConfig.mapIdentityPixel ?? context.state.config?.identity?.pixels ?? 3
        let features=options.map.getFeaturesAtPixel(options.pixel, {hitTolerance, layerFilter: (layer)=> layer === context.state.map_layer})
        return Promise.resolve(features ??  [])
      }else{
        return Promise.resolve([])
      }
      
    },
  },

}
