<template>
  <div>
    <!--  -->
    <select-surveys-by-search v-if="settings?.displayBySearch" @ids="selectedIds=$event"></select-surveys-by-search>

    <sync-options v-if="showSyncOptions" v-model="selectedSyncOption" class="ml-5 my-3"></sync-options>
    <!-- <b-btn :disabled="selected.length==0" variant="primary"> -->
    <b-btn variant="primary" @click="downloadSurveys" v-b-popover.hover="labels2.makeOffLine">
      <slot name="buttonContent"><i class="glyphicon glyphicon-download"></i></slot> {{nbTRansfer}}
    </b-btn>
    <b-form-checkbox
      v-if="!curDate"
      class="ml-3"
      v-model="downloadIncludePhotos"
    >
      {{ labels2.downloadIncludePhotos }}
    </b-form-checkbox>
    <span v-else>
      {{ labels2.downloadIncludePhotosNot }}
    </span>
    <!-- <b-btn variant="success" class="ml-3" size="sm" @click="selectAll">
      <i class="glyphicon glyphicon-check"></i>
    </b-btn> -->
    
    <!-- {{selected}} -->
    <sync-state :sync-state="syncingState" :error-message="mess" :changeCounter="changeCounter" class="my-2"></sync-state>
  </div>
</template>

<script>
  import syncState from './sync/syncState.vue'
  import syncOptions from './sync/syncOptions.vue'
  import form_utils from '@/utils/form_utils'
  import utils_gen from '@/utils/utils_gen'
  import selectSurveysBySearch from './sync/selectSurveysBySearch.vue'
  import cuid from 'cuid'

  export default {
    name: 'list-download',
    components:{
      syncState,
      syncOptions,
      selectSurveysBySearch,
    },
    props:['idsToDownload', 'curDate', 'settings'],    
    data() {
      return {
        downloadIncludePhotos:false,
        syncingState:0,//0= not sync, 1=in sync, 2=sync finiched,99=sync errors
        selectedSyncOption:{batch_size:this.$store.state.settings.pouhdb_replicate_batch_size},
        mess: null,
        selectedIds: null,
        changeCounter:0,
      }
    },
    computed: {
      labels2(){return this.$store.state.labels.form.edit},
      showSyncOptions(){
        if(this.settings && this.settings.hasOwnProperty('showSyncOptions')){
          return this.settings.showSyncOptions
        }
        return false
      },
      nbTRansfer(){
        if(this.curDate){
          return this.labels2['oneDate']
        }else if(this.idsToDownload){
          return this.idsToDownload.length + this.labels2['records']
        }else if(this.selectedIds){
          return this.selectedIds.length + this.labels2['records']
        }else{
          return this.labels2['recordsAll']
        }
      }
    },
    methods: {
      downloadSurveys(){
        this.syncingState=1
        const formId=this.$store.state.form.form_id
        let form= this.$store.getters['localDB/form_object_for_local_add'](formId)
        // We always fire the addFormLocal before, to ensure local is created and that all form_definition are present.
        const syncDoc = {
          _id: "sync_" + cuid(),
          operation: 'downloadSurveys',
          user: this.$store.getters['userName'], 
          device: this.$store.getters["localDB/device_id"],
          startTime: this.$store.getters['utcCurrentTime'](),
          formVersion: this.$store.getters['form/version'],
          appVersion: this.$store.state.settings.version,
          db: formId,
          curDate: this.curDate,
          idsToDownload: this.idsToDownload,
          selectedIds: this.selectedIds,
        };
        
        const remoteDb = new PouchDB(this.$store.state.couchdbUrl + form.id, this.$store.getters.pouchDbOptions)
        return remoteDb.put(syncDoc).then(rep=>{
          syncDoc._rev = rep.rev;
          return this.$store.dispatch('localDB/addFormLocal',form)
        }).then(()=>{
          //add a form to the local store
          const localForm=new PouchDB(form.id,this.$store.dispatch('localDB/getPouchDbOptions'))
          //replicate remote to a local db
          
          let prom1 = Promise.resolve()
          //  *** what to replicate
          if(this.curDate){
            prom1 = this.$store.dispatch('form/fetchSurveysDate', this.curDate).then(alldocs=>{
              //console.log(alldocs)
              return alldocs.map(x=> x.id)
            }).then(ids_replicate =>{
              prom1 = this.replicateSurveys(remoteDb, localForm, ids_replicate)
            })
          }else if(this.idsToDownload){
            // list of ids to download is provided:
            prom1 = this.replicateSurveys(remoteDb, localForm, this.idsToDownload)
          }else if(this.selectedIds){
            // list of ids to download is provided:
            prom1 = this.replicateSurveys(remoteDb, localForm, this.selectedIds)
          }else{
            // whole survey
            this.changeCounter = 0
            prom1 = remoteDb.allDocs({startkey:'survey_',endkey:'survey_\ufff0'}).then(docs=>{
              let ids_replicate = docs.rows.map(row => row.id)
              return localForm.replicate.from(remoteDb,
                Object.assign({doc_ids:ids_replicate},this.selectedSyncOption)
              ).on("change", info => {
                this.changeCounter += info.docs_written
              })
            }).then(()=>{
              if(this.downloadIncludePhotos){
                return remoteDb.allDocs({startkey:'photo_',endkey:'photo_\ufff0'}).then(docs=>{
                  let ids_replicate = docs.rows.map(row => row.id)
                  return localForm.replicate.from(remoteDb,
                    Object.assign({doc_ids:ids_replicate},this.selectedSyncOption)
                  ).on("change", info => {
                    this.changeCounter += info.docs_written
                  })
                })   
              }else{
                return Promise.resolve()
              }
            })
          }
          return prom1
        }).then(()=>{
          this.syncingState=2
          syncDoc.endTime = this.$store.getters['utcCurrentTime']();
          return remoteDb.put(syncDoc);
        }).catch(err=>{
          this.syncingState=99
          this.mess = null
          console.log(err)
          syncDoc.errorTime = this.$store.getters['utcCurrentTime']();
          syncDoc.errorMessage = err
          return remoteDb.put(syncDoc);
        })
      },
      replicateSurveys(fromDB, toDB, surveyIds){
        console.log(surveyIds);
        this.changeCounter = 0
        return toDB.replicate.from(fromDB,Object.assign({doc_ids: surveyIds},this.selectedSyncOption)).on("change", info => {
            this.changeCounter += info.docs_written
          }).then(rep=>{
          console.log(this.downloadIncludePhotos);
          if(this.downloadIncludePhotos){
            // 1 - get the form definition photo fields.
            const photoFields = this.$store.getters['form/actualFormDefitionFieldsFlatten'].filter(x=>x.field.type=='photo')
            console.log(photoFields);
            // 2 - get my survey data:
            return toDB.allDocs({keys: surveyIds, include_docs: true}).then(docs=>{
              // 3 - get the photos doc id
              const photoIds = []
              console.log(docs);
              docs['rows'].map(doc=>{
                const obj1 = utils_gen.flattenObjectValueArray(doc.doc['form_data'])
                console.log(doc.doc['form_data']);
                console.log(obj1);
                photoFields.forEach(fi=>{
                  if(obj1[fi.field.name]){
                    photoIds.push(...(obj1[fi.field.name].filter(xx=>!!xx)))
                  }
                })
              })
              console.log(photoIds);
              // 4 - replicate the photo docs ids.
              return toDB.replicate.from(fromDB,Object.assign({doc_ids: photoIds},this.selectedSyncOption)).on("change", info => {
                this.changeCounter += info.docs_written
              })
            })
          }else{
            return Promise.resolve(rep)
          }
        })
      }
    },
  }

</script>

<style lang="scss" scoped>

</style>