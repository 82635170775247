<template>
  <div v-if="features_by_layers && layers.length>0">
    <div v-for="lay in layers" :key="lay">
    <identity-layer
      :layer_id="lay"
      :features="features_by_layers[lay]"
      v-if="features_by_layers[lay] && features_by_layers[lay].length>0"
    ></identity-layer>
    </div>
  </div>
</template>

<script>
import base from './_base.js'
import identityLayer from './identity_layer.vue'

export default{
  name:'map-identity',
  mixins:[base],
  components:{identityLayer},
  data(){
    return{
      features_by_layers:null
    }
  },
  computed:{
    layers(){
      if(this.features_by_layers){
        return Object.keys(this.features_by_layers).filter(x=>{
          return this.features_by_layers[x].length>0
        })
      }
    },
    formComponent(){
      return this.$store.state.formMap.formComponent
    }
  },
  watch: {
    mapActivatedId(newValue, oldValue) {
      this.features_by_layers=null
    }
  },
  methods:{
    init(){
      if(this.map){
        console.log('sigle click registered')
        this.map.on('singleclick',this.singleClick)
      }
    },
    singleClick(evt){
      this.features_by_layers=null
      let xy_clicked=evt.coordinate
      console.log(xy_clicked)
      //if we have an active form component, the click is not active.
      //TODO: put a param in the formComponent to see if map still deal the click event...
      if(!this.formComponent){
        //TODO: support diffrent tool options
        //we impelment identify, but could be anything else?? what else...
        // this.$store.dispatch('map/getFeatures',{coordinate:xy_clicked,onlyVisible:true}).then(features=>{
        //   //features is an object with the layer_id as key
        //   this.features_by_layers=features
        // })
        this.$store.dispatch('map/getFeaturesAtPixel',{pixel: this.map.getEventPixel(evt.originalEvent), map: this.map}).then(features=>{
          //features is an object with the layer_id as key
          this.features_by_layers=features
        })
      }
    }
  }
}
</script>
