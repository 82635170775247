<template>
<div v-if="showElement" class="py-2" >
  <div :class="showAsCard?{'card':true, 'my-card-body':true, 'bg-dark':true}:{}">
  <div :class="showAsCard?{'card-body':true,'my-card-body':true}:{}">
  <b-list-group>
    <b-list-group-item :variant="status_list[repeatStatus].input_message?'warning':'dark'" @click="opened=!opened">
      <span class="glyphicon" :class="{'glyphicon-chevron-right':!opened,'glyphicon-chevron-down':opened}"></span>
      <span v-html="label"></span>
      <span v-show="!showListAndAdd" class="h5">
        {{editingIndex+1}} / {{value?.length ?? 0}}
      </span>
      <span v-if="status_list[repeatStatus].input_message" class="text-warning font-italic small">
        &emsp;{{labels.status_message[status_list[repeatStatus].input_message]}}
      </span>
      <b-btn-group v-if="$store.state.allowCopyPaste" class="ml-2">
        <b-btn @click.stop="copyValToClipboard()" size="sm"><i class="glyphicon glyphicon-copy"></i></b-btn>
        <b-btn @click.stop="getValFromClipboard(false)" size="sm" v-if="!me.readonly"><i class="glyphicon glyphicon-paste"></i></b-btn>
        <b-btn @click.stop="getValFromClipboard(true)" size="sm" v-if="!me.readonly"><i class="glyphicon glyphicon-plus"></i><i class="glyphicon glyphicon-paste"></i></b-btn>
      </b-btn-group>
    </b-list-group-item>
    <!-- List and editing -->
    <b-list-group-item v-show="opened">
      <div v-show="showListAndAdd && ((value?.length>0) ?? false)">
        <b-table :items="valueForTable" :fields="fields" responsive >
          <template slot="cell(editButton)" slot-scope="data">
            <b-button @click="clickIndex(data.index)" size="sm" :variant="getVariantStatus(data.index)">
              <!-- {{labels.inputs.edit}} -->
              <span class="glyphicon glyphicon-pencil"></span>
            </b-button>
          </template>
          <template v-for="phot in photoFields" :slot="'cell(' + phot.name +')'" slot-scope="data">
            {{ data.value }}
            <b-button v-if="data.value" @click="showPhoto(data.value)" variant="secondary" size="sm"><span class="glyphicon glyphicon-eye-open"></span></b-button>
          </template>
        </b-table>
      </div>
      <div v-if="editingIndex!=-1 && editingElement" :id="newElementId">
        <!-- Input group -->
        <i-group
            :config="config"
            :formReadOnly="formReadOnly"
            :formDefinition="formDefinition"
            :value="editingElement"
            @input="updateValue($event)"
            :status="editingStatus"
            :showSystemValues="true"
            :surveyDoc="surveyDoc"
            :namePath="newNamePath(editingIndex)"
            :showHeader="showListAndAdd"
            :isRootRepeat="true"
            :editingIndexGiven="editingIndexGiven"
            :readOnlyInput="readOnlyInput"
            >
        </i-group>
        
      </div>
      <!-- Always visible -->
      <!-- <div v-if="editingIndex==-1"> -->
      <div class="d-flex justify-content-between mt-2 mx-4">
        <div>
          <div v-show="showListAndAdd && !readonly && !parameters.disable_add_new ">
            <b-button @click="addNew" variant="primary">
              <!-- {{labels.inputs.add}} -->
              <span class="glyphicon glyphicon-plus"></span>
            </b-button>
          </div>
          <div v-if="showRepeatCopyFromPrevious" v-show="showListAndAdd" class="ml-5">
            <b-button @click="repeat_copy_from_previous" variant="secondary">
              <!-- {{labels.inputs.add}} -->
              <span class="glyphicon glyphicon-paste"></span>
            </b-button>
          </div>
        </div>
        <div>
          <b-button @click="editingIndex=-1" 
              variant="dark" 
              v-if="showListAndAdd && editingIndex!=-1">
            <span class="glyphicon glyphicon-minus"></span>
          </b-button>
        </div>
        <div>
          <b-button @click="deleteCurrent" 
              variant="danger" 
              :title="labels.inputs.delete"
              v-if="showListAndAdd && !readonly && !parameters.disable_delete">
            <!-- {{labels.inputs.delete}} -->
            <span class="glyphicon glyphicon-remove"></span>
          </b-button>
        </div>
      </div>
    </b-list-group-item>
  </b-list-group>
  </div>
  </div>
</div>
</template>

<script>
import repeatBase from './_baseRepeat.js'
import formUtils from '../../utils/form_utils.js'
import utilsGen from '../../utils/utils_gen.js'

export default {
  name:'i-repeat',
  mixins:[repeatBase],
  updated(){
    if(this.showElement && this.showListAndAdd==true && this.editingIndex!=-1 && this.hasToScrool){
      var options = {
        container: 'body',
        easing: 'ease-in',
        offset: -60,
        cancelable: true,
        y: true
      }
      this.$scrollTo('#'+this.newElementId,500,options)
      this.hasToScrool=false
    }
  },
  computed:{
    repeatStatus(){
      if(this.showListAndAdd){
        if(Array.isArray(this.status)){
          return formUtils.getStatusOfTree(this.status)
        }
        return this.status
      }else if(this.editingStatus){ // can be null at the beginning, because of the order of update.
        // have page and header not show, just the header of the current edit so calc a status for it
        return formUtils.getStatusOfTree(this.editingStatus)
      }
      return 20
    },
    showListAndAdd(){
      if(this.hasPages && this.showElement){
        let index=this.currentPage.pathComplete.indexOf(this.config.name)
        if(index==(this.currentPage.pathComplete.length-1)){
          return true
        }
        else if(index>-1){
          //we have pages and we are in the current path.
          //are we adding
          // if( this.currentPage.repeatNew){
          //   return true
          // }
          return false
        }
        return true
      }
      return true
    },
    showAsCard(){
      //if we show as card, it's bordered by back border. easier to see it as a whole object
      if(this.showListAndAdd){
        if(this.pageIsElementRootPage){
          return false
        }
        return true
      }
      return false
    },
    showRepeatCopyFromPrevious(){
      let rep = false
      if(this.appearance_aecom.indexOf('repeat_copy_from_previous')!=-1 && ((this.value?.length ?? 0)==0)){
        // find the upper repeat section
        this.namePath.slice(0).reverse().map(x=>{
          if (typeof x == 'number'){
            if(x != 0 ) {
              rep = true
            }
          }
        })
      }
      return rep
    },
    photoFields(){
      // check if a photo field and show button if yes 
      return this.config.children.filter(x=>x.type=='photo')
    }
  },
  watch:{
  },
  methods:{
    repeat_copy_from_previous(){
      let newNamepath = []
      let found = false
      this.namePath.slice(0).reverse().map(x=>{
        if (typeof x == 'number' && found ==  false){
          newNamepath.push(x-1)
          found = true
        }else{
          newNamepath.push(x)
        }
      })
      newNamepath.reverse()
      let obj1 = utilsGen.getPropTree2(this.surveyDoc.form_data, newNamepath)
      utilsGen.setPropTree2(this.surveyDoc.form_data,this.namePath, JSON.parse(JSON.stringify(obj1)))
    },
    showPhoto(photo_id){
      if(photo_id){
        this.getPhotoUrl(photo_id).then(photo=>{
          this.$store.commit('modals/photo',photo)
        }).catch(err=>this.$store.dispatch('app_message_error',err))
      }
    },
    getPhotoUrl(photo_id){
      if(photo_id){
        let photo={}
        return this.$store.state.form.db.get(photo_id).then(doc=>{
          photo.name=doc.name
          photo.attachmentGeoJson=doc.attachmentGeoJson // if we have already an attachment
          if(doc._attachments.annotation && forceOri==false){
            return this.$store.state.form.db.getAttachment(doc._id,'annotation' )
          }else{
            return this.$store.state.form.db.getAttachment(doc._id,'photo' )
          }
        }).then(blob=>{
          //could also use the URL of couchDB if online - https://dbforms.aecom.space/form_cjh7vrjy5000uiatendvjvkn8/photo_cjh82ywlr000giateg0v5xlf4/photo
          //but has to support offline mode (pouchDB).
          var url = URL.createObjectURL(blob);
          photo.url=url
          return Promise.resolve(photo)
        }).catch(err=>this.$store.dispatch('app_message_error',err))
      }
      return Promise.reject('no image')
    },
  },
}
</script>

<style scoped>
.my-card-body{
  padding: 0.5rem; 
}


</style>
