// as form in index.js

// reprensent the current opened form. local or remote.
//all operations should be done here.

import Vue from 'vue'
import PouchDB from 'pouchdb'
import cuid from 'cuid'
import moment from 'moment'
import form_utils from '../utils/form_utils.js'
import { Promise } from 'q';
// import { stat } from 'fs';
import getQuota from '../utils/quota'
import axios from 'axios'

import survey from './form_survey'

export default {
  namespaced: true,
  modules:{
    survey
  },
  state: {
    db: null,
    remoteDb: null,
    actualFormConfig: null,
    form_id: null,
    ChangeCounter: 0,
    loaded_form_definitions: {}, // support multiple definition in memory, to speed things if we have many different type of definition
    loaded_form_definitions_trigger: null, // as loaded_form_definitions is an object we add prop to it, the object don't change, so getters are not triggered. Use this to trigger.
    form_error_message: null, //mostly for config don't exists. see getActualConfig

    //holds information for the input groups to show the page in header
    inputGroupCurrentPage: -1, //hold the current apge - begining at index 0
    inputGroupPages: null, // hold the pages object.
    inputGroupCurrentPageJson: null,
    editingFormDefinitionId: null,
    viewMode: 1, // when views are defined in the form, 3 mode (1-normal no filter, 2-show only the view info with little indicator of hidden info, 3-show faded not in view info ) all case not valid field are shown
    viewValue: null, // hold the view name to use - still have to check the mode 
    editShowItem: 1, // the edit page item type to show (1-normal, 2-summary, 3-print, )
    currentPrintName: null,

    //used in inputGroup.vue
    //used in navbar_form_pages.vue
    //reseted in edit.vue at beforeRouteLeave
    syncIncludePhotos:true, // allow to offer sync to not include photos
    // allow to send the end in edit to a different page than the default one.
    //      used in edit.vue and identity_layer.vue
    //  - form_menu: go to form menu
    //  - form_list: go to form list
    //  - map: go to map
    editOnEndReturnTo: 'form_menu',
    formLanguage: null, // hold the languages of the form, because the form might be in a language that is not supported by the app.
    formDisplayOptions: {
      notePosition: 'above-answer', // 'above-answer', 'below-answer', 'below-question'
      hintLanguageMode: 'default+language', // 'language', 'default+language'
      selectInfoMode: 'label+info', // 'label+info', 'info', 'label'
      showFlagCreate: false,
      currentSummarySelected: null,
    } 
  },
  mutations: {
    actualFormConfig(state, doc) {
      state.actualFormConfig = doc;
    },
    db(state, db) {
      state.db = db;
    },
    remoteDb(state, remoteDb) {
      state.remoteDb = remoteDb;
    },
    form_id(state, form_id) {
      // first called, so put here what has to reset
      if (state.form_id != form_id) {
        //reset objet linked
        state.loaded_form_definitions = {};
        state.form_id = form_id;
        state.actualFormConfig = null;
        state.remoteDb = null;
        state.db = null; // if want to nullify always, has to changebelow the order.
      }
    },
    setChangeCounter(state, newVal) {
      state.ChangeCounter = newVal;
    },
    setEditOnEndReturnTo(state, newVal) {
      state.editOnEndReturnTo = newVal;
    },
    addLoadedFormDefinition(state, addDoc) {
      Vue.set(state.loaded_form_definitions, addDoc._id, addDoc)
      state.loaded_form_definitions_trigger = cuid();
    },
    form_error_message(state, mess) {
      state.form_error_message = mess;
    },
    inputGroupCurrentPage(state, newVal) {
      if (state.inputGroupPages) {
        if (newVal >= 0 && newVal < state.inputGroupPages.length) {
          state.inputGroupCurrentPage = newVal;
          const newJson = JSON.stringify(state.inputGroupPages[newVal]);
          if (state.inputGroupCurrentPageJson != newJson) {
            state.inputGroupCurrentPageJson = newJson;
          }
        }
      } else {
        // if not defined, can put any value
        state.inputGroupCurrentPage = newVal;
      }
    },
    inputGroupPages(state, newVal) {
      if (newVal && state.inputGroupCurrentPage >= newVal.length) {
        // if a page is deleted, for example a repeat.
        state.inputGroupCurrentPage = newVal.length - 1;
      }
      //have to do this because the pages is recomputed, as it's a computed prop and current page is them recomputed...
      //we want to avoid that a change within the page cause a scrool
      if (JSON.stringify(state.inputGroupPages) != JSON.stringify(newVal)) {
        state.inputGroupPages = newVal;
        if (state.inputGroupPages) {
          const newJson = JSON.stringify(
            state.inputGroupPages[state.inputGroupCurrentPage]
          );
          if (state.inputGroupCurrentPageJson != newJson) {
            state.inputGroupCurrentPageJson = newJson;
          }
        }
      }
    },
    editShowItem(state, newval){
      state.editShowItem = newval.value
      state.currentPrintName = newval.name
    },
    setViewMode(state, newval){
      // don't allow to set it to more thatn exists mode. allow or just setting new mode to +1 and not crash
      if(newval>3){
        newval=1
      }
      state.viewMode = newval
    },
    setViewValue(state, newval){
      state.viewValue = newval
    },
    setEditingFormDefinitionId(state, newval){
      state.editingFormDefinitionId = newval
    },
    setFormDisplayOptions(state, {key, val}){
      state.formDisplayOptions[key] = val
    },
  },
  actions: {
    setFormLanguage(context, newval){
      // TODO: we want to save this to the local storage, it can be remembered.
      context.state.formLanguage = newval
    },
    getDoc(context, id) {
      return context.state.db.get(id);
    },
    setActualForm(context, form_id) {
      if (form_id != context.state.form_id || context.getters['isOnline'] != context.rootGetters['localDB/isOnlineMode']) {
        //entry point for all form pages.
        context.commit("form_id", form_id);
        return context.dispatch("setRemoteDB").then(() => {
          return context.dispatch('localDB/getDatabase',{dbName: context.state.form_id},{root: true})
        }).then(rep=>{
          context.commit("db",rep.db)
          return context.dispatch("getActualConfig");
        }).catch(err => {
          if(context.state.remoteDb && context.rootState.localDB.databasesAccesMode==2){
            context.commit("form_error_message", "form_not_offline");
          }else{
            context.commit("form_error_message", "Error form_id cannot be found");
            console.error("getActualConfig");
            console.error(err);
            context.dispatch('app_message_error',err,{root:true})
          }
          return Promise.reject(err);
        });
      }
      return Promise.resolve("Form_id is already the same");
    },
    setRemoteDB(context) {
      if (context.state.form_id && context.rootState.couchdbUrl) {
        // verify not null
        return context.dispatch('localDB/getDatabase',{dbName: context.state.form_id, alwaysUseRemoteDb: true},{root: true}).then(rep =>{
          context.commit("remoteDb",rep.db)
          return Promise.resolve();
        })
      } else {
        context.commit("remoteDb", null);
      }
      return Promise.resolve(); // always resolve. we put in a promise for consistency,
    },
    getActualConfig(context, form_id) {
      context.commit("form_error_message", null);
      return context.state.db
        .get("form_config")
        .then(doc => {
          context.commit("actualFormConfig", doc);
          return Promise.resolve("Ok");
        })
        .catch(err => {
          context.commit("form_error_message", "Error form_id cannot be found");
          context.dispatch('app_message_error',"getActualConfig",{root:true})
          context.dispatch('app_message_error',err,{root:true})
          return Promise.reject(err);
        });
    },
    replicateToMaster(context, options) {
      let form_id = context.state.form_id;
      if (form_id && context.state.remoteDb) {
        // verify we have a form
        let batches_limit = 10; // default value of pouchdb
        if (options && options.batches_limit) {
          batches_limit = options.batches_limit;
        }
        let batch_size = context.rootState.settings.pouhdb_replicate_batch_size;
        if (options && options.batch_size) {
          batch_size = options.batch_size;
        }
        context.commit("setChangeCounter", 0);
        //replicate to the master:
        let db1 = getOptionsDb(context, options);
        const syncDoc = {
          _id: "sync_" + cuid(),
          operation: 'replicateToMaster',
          user: context.rootGetters['userName'], 
          device: context.rootGetters["localDB/device_id"],
          startTime: context.rootGetters['utcCurrentTime'](),
          formVersion: context.getters.version,
          appVersion: context.rootState.settings.version,
          db: db1.name
        };
        return context
          .dispatch("fetchSurveysDates", options)
          .then(rep => {
            syncDoc.surveysDates = rep;
            // now get the local list of surveys
            return getQuota.getQuota().catch(err => {
              return Promise.resolve({
                Error: "Quota not enabled or error? - firefox?"
              });
            });
          })
          .then(quota => {
            syncDoc.quota = quota;
            return context.state.remoteDb.put(syncDoc);
          })
          .then(rep => {
            syncDoc._rev = rep.rev;
            return new Promise((resolve, reject) => {
              let replicateParams = {
                batch_size: batch_size,
                batches_limit: batches_limit
              }
              if (context.state.syncIncludePhotos === false){
                replicateParams['filter'] = '_view'
                replicateParams['view'] = 'editList/creationTime'
              }
              console.log(replicateParams);
              db1.replicate
                .to(context.state.remoteDb, replicateParams)
                .on("change", info => {
                  context.commit(
                    "setChangeCounter",
                    context.state.ChangeCounter + 1
                  );
                })
                .on("complete", info => {
                  resolve(info);
                })
                .on("error", err => {
                  reject(err);
                });
            });
          })
          .then(info => {
            syncDoc.endTime = context.rootGetters['utcCurrentTime']();
            syncDoc.info = info;
            return context.state.remoteDb.put(syncDoc);
          })
          .catch(err => {
            syncDoc.errTime = context.rootGetters['utcCurrentTime']();
            syncDoc.err = err;
            context.state.remoteDb.put(syncDoc);
            return Promise.reject(err);
          });
      }
      return Promise.reject("No form_id defined");
    },
    newSurvey(context) {
      if (context.state.form_id) {
        let defs = context.state.actualFormConfig.form_definitions;
        let obj1 = {
          _id: "survey_" + cuid(),
          form_id: context.state.form_id,
          form_definition: defs[defs.length - 1],
          // form_data:null,
          // form_status:null,
          creator: context.rootGetters['userName'],
          device_id: context.rootState.localDB.config.device_id,
          creationTime: context.rootGetters['utcCurrentTime']()
        };
        return context
          .dispatch("fetchDefinition", obj1.form_definition)
          .then(doc => {
            obj1 = form_utils.buildEmptySurveyData(obj1, doc.form_definition);
            form_utils.getStatusData(obj1, doc.form_definition, {});
            return context.state.db.put(obj1);
          });
      } else {
        return Promise.reject("No form_id defined - new survey");
      }
    },
    fetchSurvey(context, params) {
      if (context.state.form_id) {
        let opts = Object.assign({conflicts: true}, params.options)
        if(params && params.options && params.options.open_revs){
          opts = params.options
        }
        return context.state.db.get(params.survey_id, opts).catch(err=>{
          // was not found...
          // are we local... if yes, check online if available.
          if (context.getters['isOnline']===false){
            return context.state.remoteDb.get(params.survey_id, Object.assign({conflicts: true}, params.options)).then(()=>{
              // we have one
              return Promise.reject("survey present in remotedb");
            })
          }
        });
      } else {
        return Promise.reject("No form_id defined");
      }
    },
    // **********************************************
    //              fetchSurveys
    // **********************************************
    fetchSurveys(context, options) {
      let db1 = getOptionsDb(context, options);
      const query = {
        include_docs: true,
        attachments: false,
        descending: true,
        //Whenever descending is set to true, we need to switch the startkey and endkey to get the results we want:
        //https://pouchdb.com/2014/04/14/pagination-strategies-with-pouchdb.html
        endkey: "survey_",
        startkey: "survey_\ufff0"
      }
      if(options && options.keys){
        query['keys'] = options.keys
        delete query['startkey']
        delete query['endkey']
      }
      return db1.allDocs(query);
    },
    fetchSurveysView(context, options) {
      let useThisDb = context.state.db
      if (options && options.useDb){
        useThisDb = options.useDb
      }
      let prefix = null
      let startKey=null
      if (context.getters.edit_user_see_only_own_device_active) {
        prefix = 'device_'
        startKey=context.rootGetters["localDB/device_id"]
      }else if(context.getters.edit_user_see_only_his_survey){
        prefix='user_'
        startKey = context.rootGetters['userName']
      }
      if (prefix) {
        //get only device
        return useThisDb
          .query("editList/" + prefix + context.getters.editGroupType, {
            group: false,
            reduce: false,
            key: [startKey]
          })
          .then(rep => {
            return rep.rows.map(x => {
              x.key = x.key[1];
              return x;
            });
          });
      } else {
        //normal case
        return useThisDb
          .query("editList/" + context.getters.editGroupType, {
            reduce: false
          })
          .then(rep => rep.rows);
      }
    },
    fetchPhotos(context, options) {
      let db1 = getOptionsDb(context, options);
      const query = {
        include_docs: true,
        attachments: true,
        descending: true,
        //Whenever descending is set to true, we need to switch the startkey and endkey to get the results we want:
        //https://pouchdb.com/2014/04/14/pagination-strategies-with-pouchdb.html
        endkey: "photo_",
        startkey: "photo_\ufff0"
      }
      if(options && options.keys){
        query['keys'] = options.keys
        delete query['startkey']
        delete query['endkey']
      }
      return db1.allDocs(query);
    },
    fetchSurveysDates(context, options = {}) {
      let db1 = getOptionsDb(context, options);
      if (!db1) {
        return Promise.resolve([]);
      }
      let prefix = null
      let startKey=null
      if (context.getters.edit_user_see_only_own_device_active || options.only_own_device) {
        prefix = 'device_'
        startKey=context.rootGetters["localDB/device_id"]
      }else if(context.getters.edit_user_see_only_his_survey){
        // **** the user can only see his survey - creator
        prefix='user_'
        startKey = context.rootGetters['userName']
      }
      if (prefix) {
        //get only device
        return db1
          .query("editList/" + prefix +  context.getters.editGroupType, {
            group: true,
            startkey: [startKey],
            endkey: [startKey, {}]
          })
          .then(rep => {
            return Promise.resolve(
              rep.rows.map(x => {
                x.key = x.key[1];
                return x;
              })
            );
          });
      } else {
        //normal case
        return db1
          .query("editList/" + context.getters.editGroupType, { group: true })
          .then(rep => rep.rows);
      }
    },
    fetchSurveysDate(context, mydate) {
      let prefix = null
      let startKey=null
      if (context.getters.edit_user_see_only_own_device_active) {
        prefix = 'device_'
        startKey=context.rootGetters["localDB/device_id"]
      }else if(context.getters.edit_user_see_only_his_survey){
        prefix='user_'
        startKey = context.rootGetters['userName']
      }
      const q1 = {
        reduce: false,
      }
      if (prefix) {
        //get only device
        q1['group']= false
        if(mydate=='all'){
          q1['key']= [startKey]
        }else{
          q1['key']= [startKey, mydate]
        }
        return context.state.db
          .query("editList/" + prefix + context.getters.editGroupType, q1)
          .then(rep => {
            return rep.rows.map(x => {
              x.key = x.key[1];
              return x;
            });
          });
      } else {
        //normal case
        if(mydate!='all'){
          q1['key']= mydate
        }
        return context.state.db
          .query("editList/" + context.getters.editGroupType, q1)
          .then(rep => rep.rows);
      }
    },
    fetchSurveysDateWithDocs(context, mydate) {
      //useful in edit for deletion - only by admin
      return context.state.db
        .query("editList/" + context.getters.editGroupType, {
          key: mydate,
          reduce: false,
          include_docs: true
        })
        .then(rep => rep.rows);
    },
    // **********************************************
    //              fetchSurveys - END
    // **********************************************
    fetchDefinition(context, definition_id) {
      if (context.state.form_id) {
        if (
          context.state.loaded_form_definitions.hasOwnProperty(definition_id)
        ) {
          return Promise.resolve(
            context.state.loaded_form_definitions[definition_id]
          );
        }
        return new Promise((resolve, reject) => {
          context.state.db
            .get(definition_id)
            .then(doc => {
              doc.form_definition._id=definition_id // so our form_deinition object has the id
              context.commit("addLoadedFormDefinition", doc);
              resolve(doc);
            })
            .catch(err => {
              reject('Form definition missing: '+ definition_id + ' / ' + err.toString());
            });
        });
      } else {
        return Promise.reject("fetchDefinition=>No form_id defined");
      }
    },
    getActualFormDefition(context) {
      return context.dispatch(
        "fetchDefinition",
        context.getters.actualFormDefitionId
      );
    },
    saveSurvey(context, newSurveyDoc) {
      if (context.state.form_id && newSurveyDoc) {
        //before putting it, inform the map that it's been modified-usually will delete it.
        //TODO: see note in map.
        context.dispatch(
          "project/editedSurvey",
          { id: newSurveyDoc._id, form_id: context.state.form_id },
          { root: true }
        );
        //put the doc
        newSurveyDoc.editor = context.rootGetters['userName'];
        newSurveyDoc.editor_device_id =
          context.rootState.localDB.config.device_id;
        newSurveyDoc.editor_time = context.rootGetters['utcCurrentTime']();
        // For debug in prod database....
        // return Promise.resolve(true);
        return context.state.db.put(newSurveyDoc);
      } else {
        return Promise.reject("No form_id defined");
      }
    },
    saveSurveyNoEditor(context, newSurveyDoc) {
      if (context.state.form_id && newSurveyDoc) {
        //before putting it, inform the map that it's been modified-usually will delete it.
        //TODO: see note in map.
        context.dispatch(
          "project/editedSurvey",
          { id: newSurveyDoc._id, form_id: context.state.form_id },
          { root: true }
        );
        //put the doc
        if(!newSurveyDoc.begin_edit){
          newSurveyDoc.begin_edit = []
        }
        newSurveyDoc.begin_edit.push({ 
          utcTime: context.rootGetters['utcCurrentTime'](),
          user: context.rootGetters['userName'] + '_batch_validate',
          device: context.rootGetters['localDB/device_id'] ,
          formStatus:newSurveyDoc.form_status_global,
          rev: newSurveyDoc._rev,
        })
        return context.state.db.put(newSurveyDoc);
      } else {
        return Promise.reject("No form_id defined");
      }
    },
    saveSurveys(context, surveyDocs) {
      if (context.state.form_id && surveyDocs) {
        //before putting it, inform the map that it's been modified-usually will delete it.
        //TODO: see note in map.
        let docs = surveyDocs.map(x => {
          context.dispatch(
            "project/editedSurvey",
            { id: x._id, form_id: context.state.form_id },
            { root: true }
          );
          x.editor = context.rootGetters['userName'];
          x.editor_device_id = context.rootState.localDB.config.device_id;
          x.editor_time = context.rootGetters['utcCurrentTime']();
          return x;
        });
        //put the docs
        return context.state.db.bulkDocs(docs);
      } else {
        return Promise.reject("No form_id defined");
      }
    },
    uploadPhoto(context, options) {
      return context.state.db.put({
        _id: "photo_" + cuid(),
        creator: context.rootGetters['userName'],
        device_id: context.rootState.localDB.config.device_id,
        creationTime: context.rootGetters['utcCurrentTime'](),
        name: options.fileName,
        type: "photo",
        content_type: options.fileType,
        survey_id: options.survey_id,
        _attachments: {
          photo: {
            content_type: options.fileType,
            //type: file.type,
            data: options.file
          }
        }
      });
    },
    getOnlyLocalDocs(context) {
      //used to count the number of document to sync.
      if (context.getters['isOnline']===false) {
        let docsLocal = [];
        let docsRemote = [];
        //TODO: make better, as now we only have phtoos and surveys... but it's only indication.
        // we filter by start and end to avoid getting a huge number of survey/photo
        return context.state.db
          .allDocs({ startkey: "photo_", endkey: "photo_\ufff0" })
          .then(docs => {
            // don't return the deleted documents... cannot show them...
            //todo: could we correct that?
            docsLocal = docsLocal.concat(docs.rows);
            // get photos from the remote
            if (docs.rows.length > 0) {
              return context.state.remoteDb.allDocs({
                startkey: docs.rows[0].id,
                endkey: docs.rows[docs.rows.length - 1].id
              });
            } else {
              // we don't need anything, al there are no local documents, but still want an answer for compatibility with the following steps.
              return context.state.remoteDb.allDocs({ key: "nothing" });
            }
          })
          .then(remotePhotos => {
            docsRemote = docsRemote.concat(remotePhotos.rows);
            //now the surveys
            return context.state.db.allDocs({
              startkey: "survey_",
              endkey: "survey_\ufff0"
            });
          })
          .then(localSurveys => {
            docsLocal = docsLocal.concat(localSurveys.rows);
            if (localSurveys.rows.length > 0) {
              return context.state.remoteDb.allDocs({
                startkey: localSurveys.rows[0].id,
                endkey: localSurveys.rows[localSurveys.rows.length - 1].id
              });
            } else {
              // we don't need anything, al there are no local documents, but still want an answer for compatibility with the following steps.
              return context.state.remoteDb.allDocs({ key: "nothing" });
            }
          })
          .then(remoteSurvey => {
            docsRemote = docsRemote.concat(remoteSurvey.rows);
            // *** build the output - only local documents
            let differenceWith = require("lodash/differenceWith");
            let onlyLocal = differenceWith(docsLocal, docsRemote, (x1, x2) => {
              //diff function
              if (x1.id == x2.id && x1.value.rev == x2.value.rev) {
                return true;
              }
              return false;
            });
            return Promise.resolve(onlyLocal);
          });
      } else {
        return Promise.resolve(0);
      }
    },
    performFind(context, options) {
      if(context.getters.isOnline){
        return axios.post(context.state.db.name + '/_find', options.query, {
            headers: {
                "Content-Type": "application/json",
                Authorization: "Bearer " + localStorage.getItem('user-token-couchdb')
            }
        }).then(response => {
            return Promise.resolve(response.data.docs);
        })
      }
      return Promise.reject("Not online");
    },
    // ********************************************
    //          Design documents
    // ********************************************
    createDesignDocs(context, options = {}) {
      //first create the geometries doc
      // *** important*** If add more - also add in the addFormLocal in localDbStore.js
      // add to the ids list that is replicated
      // so they are available offline
      const tasks = [
        context.dispatch("createDesignDocs_geom", options),
        context.dispatch("createDesignDocs_editList", options),
        context.dispatch("createDesignDocs_summary", options)
      ];
      return Promise.all(tasks);
    },
    createDesignDocs_geom(context, options) {
      let db1 = getOptionsDb(context, options);
      //first create the geometries doc
      return context.dispatch("getActualFormDefition").then(doc => {
        // Map info text =>
        // the list_aecom that define the edit fields. (Default)
        // calculation_map_info => if prensent, it will be used, it override the default - the fields is calculated at the same time of other calcultion
        const editListFieldsForm = context.getters.editListFieldsForm.map(x =>
          ["form_data"].concat(x.path)
        );
        let views = form_utils.getDesignGeometryViews(doc.form_definition, {
          editListFieldsForm: editListFieldsForm
        });
        // console.log(views)
        if (Object.keys(views).length == 0) {
          // if no geometry views, don't create
          return Promise.resolve(false);
        }
        return db1
          .get("_design/geometries")
          .then(rep => {
            //replace views
            rep.views = views;
            return db1.put(rep);
          })
          .catch(err => {
            //create
            return db1.put({
              _id: "_design/geometries",
              views: views
            });
          });
      });
    },
    createDesignDocs_summary(context, options) {
      // create summary design doc
      let db1 = getOptionsDb(context, options);
      //first create the geometries doc
      return context.dispatch("getActualFormDefition").then(doc => {
        let views = form_utils.getDesignSummaryViews(doc.form_definition);
        console.log(views)
        if (Object.keys(views).length == 0) {
          // if no geometry views, don't create
          return Promise.resolve(false);
        }
        return db1
          .get("_design/summary")
          .then(rep => {
            //replace views
            rep.views = views;
            return db1.put(rep);
          })
          .catch(err => {
            //create
            return db1.put({
              _id: "_design/summary",
              views: views
            });
          });
      });
    },
    createDesignDocs_editList(context, options) {
      let db1 = getOptionsDb(context, options);
      // get the list of fields
      let editListFields = context.getters.editListFields;
      editListFields = editListFields.map(x => {
        if (Array.isArray(x)) {
          return ["doc"].concat(x).join(".");
        } else {
          return "doc." + x;
        }
      });
      // Also add at the end the field to indicate if conflicts
      editListFields.push( "doc._conflicts?true:false")
      // ******** be carful to put the string in quote, even if possible to to toString, but the build change the variable name...
      var ddoc = {
        _id: "_design/editList",
        views: {
          editor_time: {
            map: `function(doc) {
              try {
                if(doc && doc._id.indexOf('survey_')==0 && doc.survey_deleted !== true){
                  // var a=new Date(doc.editor_time);
                  // var d1=a.getFullYear()+'-'+('0'+(a.getMonth()+1)).slice(-2)+'-'+('0'+a.getDate()).slice(-2);
                  var d1 = doc.creationTime.substring(0,10);
                  if(doc.editor_time){
                    d1 = doc.editor_time.substring(0,10);
                  }
                  emit(d1,[]) ;
                }
              } catch (e) {
                
              }
            }`
              // .toString()
              .replace("[]", "[" + editListFields.join(",") + "]"),
            reduce: "_count"
          },
          creationTime: {
            map: `function(doc) {
              try {
                if(doc && doc._id.indexOf('survey_')==0 && doc.survey_deleted !== true){
                  // var a=new Date(doc.creationTime);
                  // var d1=a.getFullYear()+'-'+('0'+(a.getMonth()+1)).slice(-2)+'-'+('0'+a.getDate()).slice(-2);
                  var d1 = doc.creationTime.substring(0,10);
                  emit(d1,[]) ;
                }
              } catch (e) {
                
              }
            }`
              // .toString()
              .replace("[]", "[" + editListFields.join(",") + "]"),
            reduce: "_count"
          },
          // for device_id
          device_editor_time: {
            map: `function(doc) {
              try {
                if(doc && doc._id.indexOf('survey_')==0 && doc.survey_deleted !== true){
                  // var a=new Date(doc.editor_time);
                  // var d1=a.getFullYear()+'-'+('0'+(a.getMonth()+1)).slice(-2)+'-'+('0'+a.getDate()).slice(-2);
                  var d1 = doc.creationTime.substring(0,10);
                  if(doc.editor_time){
                    d1 = doc.editor_time.substring(0,10);
                  }
                  emit([doc.device_id,d1],[]) ;
                }
              } catch (e) {
                
              }
            }`
              // .toString()
              .replace("[]", "[" + editListFields.join(",") + "]"),
            reduce: "_count"
          },
          device_creationTime: {
            map: `function(doc) {
              try {
                if(doc && doc._id.indexOf('survey_')==0 && doc.survey_deleted !== true){
                  // var a=new Date(doc.creationTime);
                  // var d1=a.getFullYear()+'-'+('0'+(a.getMonth()+1)).slice(-2)+'-'+('0'+a.getDate()).slice(-2);
                  var d1 = doc.creationTime.substring(0,10);
                  emit([doc.device_id,d1],[]) ;
                }
              } catch (e) {
                
              }
            }`
              // .toString()
              .replace("[]", "[" + editListFields.join(",") + "]"),
            reduce: "_count"
          },
          // for device_id
          user_editor_time: {
            map: `function(doc) {
              try {
                if(doc && doc._id.indexOf('survey_')==0 && doc.survey_deleted !== true){
                  var d1 = doc.creationTime.substring(0,10);
                  if(doc.editor_time){
                    d1 = doc.editor_time.substring(0,10);
                  }
                  emit([doc.creator,d1],[]) ;
                }
              } catch (e) {
                
              }
            }`
              // .toString()
              .replace("[]", "[" + editListFields.join(",") + "]"),
            reduce: "_count"
          },
          user_creationTime: {
            map: `function(doc) {
              try {
                if(doc && doc._id.indexOf('survey_')==0 && doc.survey_deleted !== true){
                  var d1 = doc.creationTime.substring(0,10);
                  emit([doc.creator,d1],[]) ;
                }
              } catch (e) {
                
              }
            }`
              // .toString()
              .replace("[]", "[" + editListFields.join(",") + "]"),
            reduce: "_count"
          }
        }
      };
      // update or create the document
      return db1
        .get("_design/editList")
        .then(doc => {
          // doc exist, update the _rev
          console.log("update design doc editList - " + db1.name);
          ddoc._rev = doc._rev;
          return db1.put(ddoc);
        })
        .catch(err => {
          //doc does not exists - create
          console.log("create design doc editList - " + db1.name);
          return db1.put(ddoc);
        });
    },
    // ********************************************
    //          Design documents - END
    // ********************************************
    getActualFormSummaries(context,options= {}){
      if(context.getters.actualFormDefition){
        let db1 = getOptionsDb(context, options);
        return db1.get("_design/summary").then(doc=>{
          const rep = []
          Object.keys(doc.views).map(key=>{
            rep.push({
              label: doc.views[key].label || key, 
              hasReduce: doc.views[key].reduce?true:false,
              query: 'summary/'+key,
              cols: doc.views[key].cols,
              reduce: doc.views[key].reduce,
              options: doc.views[key].options,
            })
          })
          return Promise.resolve(rep)
        }).catch(err =>{
          return Promise.resolve([]);
        })
      }
    },
  },
  getters: {
    isOnline(state){
      if(state.db){
        if(state.db.name.indexOf('http')==0){
          return true
        }
        return false
      }
      return undefined
    },
    hasLocal(state, getters, rootState, rootGetters) {
      return rootGetters["localDB/hasLocalForm"](state.form_id);
    },
    version(state) {
      if (state.actualFormConfig) {
        return state.actualFormConfig._rev.split("-")[0];
      }
    },
    actualFormDefitionId(state) {
      // to get the definition: (return promise)
      //let definition=this.$store.dispatch('form/fetchDefinition',this.$store.getters['form/actualFormDefitionId']).then(doc=>{
      // for definition js, get property: form_definition
      if (state.actualFormConfig) {
        let defs = state.actualFormConfig.form_definitions;
        return defs[defs.length - 1];
      }
      // console.error('actualFormDefitionId=>form not loaded'); // normal as fired when mutation form_id and them refresh.
      return null;
    },
    actualFormDefition(state, getters) {
      if (getters.actualFormDefitionId) {
        if (
          state.loaded_form_definitions_trigger &&
          state.loaded_form_definitions.hasOwnProperty(getters.actualFormDefitionId)
        ) {
          return state.loaded_form_definitions[getters.actualFormDefitionId].form_definition;
        }
      }
      return null;
    },
    actualFormDefitionSetting: (state, getters) => (
      parameterName,
      defaultVal = false,
      boolTransform=false
    ) => {
      if (
        getters.actualFormDefition &&
        getters.actualFormDefition.hasOwnProperty(parameterName)
      ) {
        if (boolTransform){
          if (["no", "false", "non"].indexOf(getters.actualFormDefition[parameterName].toLowerCase()) > -1) {
            return false;
          } else if (["yes", "true", "oui"].indexOf(getters.actualFormDefition[parameterName].toLowerCase()) > -1) {
            return true;
          } else{
            return defaultVal;
          }
        }else{
          return getters.actualFormDefition[parameterName]
        }
      }
      return defaultVal;
    },
    actualFormDefitionFieldsFlatten (state, getters) {
      if (getters.actualFormDefition) {
        return form_utils.getFieldsFlatten(getters['actualFormDefition']);
      }
      return null;
    },
    get_project_id(state) {
      if (state.actualFormConfig) {
        return state.actualFormConfig.project_id;
      }
    },
    isMapActive(state, getters) {
      if (state.actualFormConfig && getters.actualFormDefition) {
        if (state.actualFormConfig.hasOwnProperty("isMapActive")) {
          return state.actualFormConfig.isMapActive;
        }
        return getters.actualFormDefitionSetting("isMapActive", true, true);
      }
      return false;
    },
    currentPage(state) {
      //have to do this because the pages is recomputed, as it's a computed prop and current page is them recomputed...
      //we want to avoid that a change within the page cause a scrool
      if (state.inputGroupCurrentPageJson) {
        return JSON.parse(state.inputGroupCurrentPageJson);
      }
    },
    editListFields(state, getters) {
      // get the list of fields that we need for the list of documents
      // can be configured
      if (getters.actualFormDefition) {
        let listForm = getters.editListFieldsForm.map(x =>
          ["form_data"].concat(x.path)
        );
        //system fields + forms fields
        // if we add a field hare, also add it in the labels form.edit.fields so it has a label.
        return [
          "creationTime",
          "editor_time",
          "editor_device_id",
          "creator",
          "editor",
          "device_id",
          "form_status_global"
        ].concat(listForm);
      }
    },
    editListFieldsForm(state, getters) {
      // the list of the edit fields defined in the xls form
      if (getters.actualFormDefition) {
        let labels = [];
        let listForm = form_utils.getEditField(
          getters.actualFormDefition,
          labels
        );
        listForm = listForm.map((x, i) => {
          return {
            path: x,
            name: x[x.length - 1],
            label: labels[i]
          };
        });
        //system fields + forms fields
        return listForm;
      }
    },
    editGroupType(state, getters) {
      let edit_group_type = "creationTime";
      if (getters.actualFormDefition) {
        if (
          getters.actualFormDefition &&
          getters.actualFormDefition.edit_group_type
        ) {
          edit_group_type = getters.actualFormDefition.edit_group_type;
        }
      }
      return edit_group_type;
    },
    edit_user_see_only_own_device_active(
      state,
      getters,
      rootState,
      rootGetters
    ) {
      if (
        getters.actualFormDefition &&
        getters.actualFormDefition.edit_user_see_only_own_device
      ) {
        const users = getters.actualFormDefition.edit_user_see_only_own_device
          .split(",")
          .map(x => x.trim());
        if (users.indexOf(rootGetters['userName']) > -1) {
          return true;
        }
      }
      return false;
    },
    edit_user_see_only_his_survey(
      state,
      getters,
      rootState,
      rootGetters
    ) {
      if (
        getters.actualFormDefition &&
        getters.actualFormDefition.edit_user_see_only_his_survey
      ) {
        if(rootGetters['isAdmin']){
          // if admin
          return false
        } 
        // if role is in the system can edit all surveys
        if(
          rootState.session && 
          rootState.settings.role_can_edit_all_survey && 
          rootState.session.userCtx.roles.filter(x => rootState.settings.role_can_edit_all_survey.split(',').indexOf(x) !== -1 ).length > 0
        ){
          return false
        }
        // if role is an exception
        if( 
          rootState.session && 
          getters.actualFormDefition.edit_user_see_only_his_survey_except_role &&
          rootState.session.userCtx.roles.filter(x=> getters.actualFormDefition.edit_user_see_only_his_survey_except_role.split(',').indexOf(x) != -1 ).length > 0
        ){
          return false
        }
        if(['true','vrai','1'].indexOf(getters.actualFormDefition.edit_user_see_only_his_survey.toLowerCase())!=-1){
          return true
        }
      }
      return false;
    },
    hasViews(state){
      if (state.editingFormDefinitionId && state.loaded_form_definitions[state.editingFormDefinitionId]){
        const def = state.loaded_form_definitions[state.editingFormDefinitionId].form_definition
        if (def.viewsFieldName){
          return true
        }
      }
      return false
    },
    formLanguages(state, getters) {
      const languages = []
      const fn1 = (fieldProp) => {
        if(typeof fieldProp === 'object'){
          Object.keys(fieldProp).map(lang=>{
            if (languages.indexOf(lang) == -1) {
              languages.push(lang)
            }
          })
        }
      }
      getters.actualFormDefitionFieldsFlatten?.map(x => {
        fn1(x.field.label)
        fn1(x.field.info)
        fn1(x.field.hint)
      })
      if (languages.length > 0 && languages.indexOf('default') == -1) {
        languages.push('default')
      }
      return languages
    },
    userCanCreateFlag(state, getters, rootState, rootGetters){
      if (getters.actualFormDefitionSetting('flagsEnabled',false,true)){
        // can our user create flags?
        const creatorRoles = getters.actualFormDefitionSetting('flagsCreatorRoles','').split(',').map(x=>x.trim())
        if(creatorRoles.length==0 || creatorRoles.filter(x=>rootGetters.roles.indexOf(x)!=-1).length>0){
          return true
        }
      }
      return false
    },
    userCanSolveFlag(state, getters, rootState, rootGetters){
      if (getters.actualFormDefitionSetting('flagsEnabled',false,true)){
        // can our user create flags?
        const creatorRoles = getters.actualFormDefitionSetting('flagsValidatorsRoles','').split(',').map(x=>x.trim())
        if(creatorRoles.length==0 || creatorRoles.filter(x=>rootGetters.roles.indexOf(x)!=-1).length>0){
          return true
        }
      }
      return false
    },
  }
};

const getOptionsDb=(context,options, rootState, rootGetters)=>{
  let db1=context.state.db
  if(options.remote==true){
    db1=context.state.remoteDb
  }
  if(options.useThisDb){ // a specific DB has been provided - useful for null/lost DB.
    db1=options.useThisDb
  }
  return db1
}